<template>
  <div>
    <!-- CABEÇALHO DE INICIO DA PÁGINA -->
    <b-card-code no-body v-if="!acesso.CadastroUsuario">
      <b-col md="12" align="left">
        <b-card-text class="mb-1 text-danger"> <NaoAutorizadoModal /> {{ acesso.CadastroUsuario }} </b-card-text>
      </b-col>
    </b-card-code>
    <b-card-code no-body v-else>
      <b-card-body no-body>
        <b-col md="auto" aling="right" v-show="acesso.CadastroUsuarioCriarNovo"> </b-col>
        <b-row>
          <b-col md="2">
            <b-form-group label="Opção" label-for="opcao">
              <b-form-select id="tipo" :options="tipoOpcoes" v-model="pesquisar.tipoOpcoes" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Descrição" label-for="descricao">
              <b-form-input
                id="descricao"
                placeholder="Descrição"
                v-model="pesquisar.descricao"
                v-on:keydown.enter="carregarGrid"
              />
            </b-form-group>
          </b-col>
          <b-col md="auto">
            <b-form-group label="Status" label-for="status">
              <b-form-select id="status" :options="tipoStatus" v-model="pesquisar.status" />
            </b-form-group>
          </b-col>
          <b-col md="auto" v-if="acesso.CadastroUsuarioConsulta">
            <b-button
              :disabled="isBusy"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="carregarGrid()"
            >
              {{ !isBusy ? 'Pesquisar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
          </b-col>
          <b-col md="auto" align="right" v-show="this.acesso.CadastroUsuarioCriarNovo">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="novoRegistro()"
            >
              Novo Cadastro
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>

      <b-col md="12" align="left" v-show="!acesso.CadastroUsuarioExcluir && !acesso.CadastroUsuarioEditar">
        <b-card-text class="mb-1 text-danger">
          A coluna "OPÇÕES" está desativada. Seu usuário não tem essa permissão
        </b-card-text>
      </b-col>

      <!-- GRID HEAD -->
      <b-table
        v-show="items.length > 0"
        striped
        responsive
        class="position-relative"
        :per-page="pesquisar.perPage"
        :items="items"
        :fields="fields"
        :busy="isBusy"
        :current-page="pesquisar.currentPage"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Carregando...</strong>
          </div>
        </template>
        <!-- GRID BOTAO -->
        <template #cell(opcoes)="row" v-if="acesso.CadastroUsuarioExcluir && acesso.CadastroUsuarioEditar">
          <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                title="Este botão contém as opções disponíveis para este registro"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
            </template>

            <b-dropdown-item @click="editar(row.item.id)" v-show="acesso.CadastroUsuarioEditar">
              <feather-icon icon="EditIcon" size="16" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="authEAlteraStatus(row.item)" v-show="acesso.CadastroUsuarioExcluir">
              <feather-icon v-if="row.item.ativo" icon="UserXIcon" size="16" />
              <feather-icon v-else icon="UserCheckIcon" size="16" />
              <span class="align-middle ml-50">
                {{ row.item.ativo ? 'Desativar' : 'Ativar' }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <!-- GRID iNFO -->
        <template #cell(avatar)="row">
          <b-avatar :src="row.item.foto != null ? row.item.foto.foto : null" />
        </template>

        <template #cell(role)="row">
          <b-badge :variant="role[0][row.item.role]" v-if="row.item.role !== 'user'">
            {{ row.item.role }}
          </b-badge>
          <b-badge :variant="role[0][row.item.role]" v-else> usuário </b-badge>
        </template>

        <template #cell(ativo)="row">
          <b-badge v-if="row.item.ativo === true" class="ml-1" variant="success"> Ativado </b-badge>
          <b-badge v-else-if="row.item.ativo === false && row.item.tentativasLogin" class="ml-1" variant="warning">
            Bloqueado
          </b-badge>
          <b-badge v-else class="ml-1" variant="danger"> Desativado </b-badge>
        </template>
      </b-table>
      <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Por Página"
          style="align-items: center"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            first-number
            last-number
            v-model="pesquisar.currentPage"
            :total-rows="totalRows"
            :per-page="pesquisar.perPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>

      <!-- INICIO DO MODAL DE CADASTRO -->
      <b-modal
        id="modal-lg"
        ref="modal-lg"
        centered
        size="lg"
        title="Cadastro do usuário"
        cancel-title="Fechar"
        ok-title="Salvar"
        cancel-variant="outline-secondary"
        @ok="salvar"
      >
        <validation-observer ref="formTomador">
          <b-form>
            <!-- Role / Permissao para Master-->
            <b-row v-if="userData.role == 'master'">
              <b-col md="12">
                <b-form-group label="Tipo de Usuário" label-for="role">
                  <validation-provider #default="{ errors }" name="Tipo de Usuário" rules="required">
                    <b-form-select
                      name="role"
                      v-model="dados.role"
                      :options="roleOpcoes"
                      @change="carregarInstituto()"
                    ></b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Role / Permissao para admin-->
            <b-row v-if="userData.role == 'admin' && !mesmoUsuario">
              <b-col md="12">
                <b-form-group label="Tipo de Usuário" label-for="role">
                  <validation-provider #default="{ errors }" name="Tipo de Usuário" rules="required">
                    <b-form-select name="role" v-model="dados.role" :options="roleOpcoesAdmin"></b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Role / Permissao para user-->
            <b-row v-if="userData.role === 'user'">
              <b-col md="12">
                <b-form-group label="Tipo de Usuário" label-for="role">
                  <b-form-input id="role" name="role" :value="'Usuário'" disabled></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Instituto para master-->
            <b-row v-if="userData.role == 'master' && dados.role != 'master'">
              <b-col md="12">
                <b-form-group label="Unidade Gestora" label-for="instituto">
                  <validation-provider #default="{ errors }" name="Instituto" rules="required">
                    <v-select-pt
                      :multiple="userData.role == 'master' && dados.role == 'admin'"
                      name="instituto"
                      v-model="dados.Instituto"
                      :options="instituto"
                      :disabled="userData.role != 'master'"
                      :reduce="(option) => option.id"
                      label="nome"
                      v-on:input="carregarGrupoAcesso(dados.Instituto)"
                    ></v-select-pt>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Grupo de Acesso -->
            <b-row v-if="dados.role != 'master' && !mesmoUsuario && userData.role != 'user'">
              <b-col md="12">
                <b-form-group label="Grupo de Acesso" label-for="grupoAcesso">
                  <validation-provider #default="{ errors }" name="Grupo de Acesso" rules="required">
                    <v-select-pt
                      :multiple="userData.role == 'master' && dados.role == 'admin'"
                      name="grupoAcesso"
                      v-model="dados.GrupoAcesso"
                      :options="grupoAcesso"
                      label="nome"
                      v-on:input="
                        dados.GrupoAcesso && dados.GrupoAcesso.length > 1 ? verificarGrupoAcesso(dados.GrupoAcesso) : false
                      "
                    ></v-select-pt>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- Nome -->
              <b-col md="6">
                <b-form-group label="Nome Completo" label-for="nomeCompleto">
                  <validation-provider #default="{ errors }" name="Nome Completo" rules="required">
                    <b-form-input
                      id="nomeCompleto"
                      v-bloqueioCaractereMask="{ tamanho: 999, regex: /[^A-Za-z ]/ }"
                      type="text"
                      placeholder="Nome Completo"
                      v-model="dados.name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- E-mail -->
              <b-col md="6">
                <b-form-group label="E-mail" label-for="email">
                  <validation-provider #default="{ errors }" name="E-mail" rules="required|email">
                    <b-form-input
                      id="email"
                      type="email"
                      @blur="isEmailValid"
                      placeholder="email@provedor.com"
                      v-model="dados.email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- Data nascimento -->
              <b-col md="3">
                <b-form-group label="Data Nascimento" label-for="dataNascimento">
                  <validation-provider #default="{ errors }" name="Data Nascimento" rules="required|data">
                    <cleave
                      id="dataNascimento"
                      class="form-control"
                      type="text"
                      placeholder="DD/MM/AAAA"
                      v-model="dados.dataNascimento"
                      :options="options.cleaveDate"
                      :raw="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Genero -->
              <b-col md="5">
                <b-form-group label="Gênero" label-for="genero">
                  <validation-provider #default="{ errors }" name="Gênero" rules="required">
                    <b-form-select name="genero" v-model="dados.genero" :options="generoOpcoes"></b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- TELEFONE -->
              <b-col md="4">
                <b-form-group label-for="telefone" label="Telefone">
                  <validation-provider #default="{ errors }" name="Telefone" rules="required|telefone">
                    <cleave
                      id="telefone"
                      v-model="dados.telefone"
                      class="form-control"
                      :raw="false"
                      :options="options.clevePhone"
                      placeholder="Telefone"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- nova senha -->
              <b-col md="6" v-if="items.mode == 'novo'">
                <b-form-group label-for="conta-nova-senha" label="Senha">
                  <validation-provider #default="{ errors }" name="Nova senha" vid="Password" rules="required">
                    <b-form-group>
                      <b-form-input
                        id="conta-nova-senha"
                        v-model="dados.password"
                        type="password"
                        name="nova-senha"
                        placeholder="Nova senha"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <div class="d-flex flex-column text-xs">
                    <small :class="isValidoPoliticaSenha('senhaQuantidadeCaractere') ? 'tw-text-zinc-700' : 'tw-text-red-500'"
                      >No mínimo {{ politicaSenhas.senhaQuantidadeCaractere }} caracteres</small
                    >
                    <small
                      v-if="politicaSenhas.senhaCaractereEspecial"
                      :class="isValidoPoliticaSenha('senhaCaractereEspecial') ? 'tw-text-zinc-700' : 'tw-text-red-500'"
                      >Ao menos um caractere especial</small
                    >
                    <small
                      v-if="politicaSenhas.senhaLetraMaiuscula"
                      :class="isValidoPoliticaSenha('senhaLetraMaiuscula') ? 'tw-text-zinc-700' : 'tw-text-red-500'"
                      >Ao menos uma letra maiúscula</small
                    >
                    <small
                      v-if="politicaSenhas.senhaLetraMinuscula"
                      :class="isValidoPoliticaSenha('senhaLetraMinuscula') ? 'tw-text-zinc-700' : 'tw-text-red-500'"
                      >Ao menos uma letra minúscula</small
                    >
                    <small
                      v-if="politicaSenhas.senhaNumero"
                      :class="isValidoPoliticaSenha('senhaNumero') ? 'tw-text-zinc-700' : 'tw-text-red-500'"
                      >Ao menos um número</small
                    >
                  </div>
                </b-form-group>
              </b-col>

              <!-- digitar novamente a nova senha -->
              <b-col md="6" v-if="items.mode == 'novo'">
                <b-form-group label-for="conta-nova-senha-denovo" label="Confirme a senha">
                  <validation-provider #default="{ errors }" name="Confirme a nova senha" rules="required|confirmed:Password">
                    <b-form-group>
                      <b-form-input
                        id="conta-nova-senha-denovo"
                        v-model="dados.password2"
                        type="password"
                        name="nova-senha-denovo"
                        placeholder="Digite novamente a senha"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--/ retype password -->
            </b-row>
            <b-row v-if="!mesmoUsuario">
              <b-col md="auto" v-if="dados.role !== 'master'">
                <b-form-group label="Vendedor" label-for="usuarioVendedor">
                  <b-checkbox
                    id="usuarioVendedor"
                    v-model="dados.vendedor"
                    name="vendedor"
                    :value="true"
                    :unchecked-value="false"
                    switch
                  >
                    {{ dados.vendedor == true ? 'Sim' : 'Não' }}
                  </b-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="auto" v-if="dados.role !== 'master'">
                <b-form-group label="Bloqueio de Ip" label-for="bloqueioIp">
                  <cleave
                    id="bloqueioIp"
                    v-model="dados.bloqueioIp"
                    class="form-control"
                    :raw="false"
                    :options="options.cleaveIp"
                    placeholder="Bloqueio de Ip"
                  />
                </b-form-group>
              </b-col>

              <b-col md="7" v-if="dados.role !== 'master'">
                <b-form-group label="Bloqueio por Horário" label-for="bloqueioHorario">
                  <div ref="bloqueioHorario" class="d-flex flex-row">
                    <validation-provider
                      #default="{ errors }"
                      name="Bloqueio Inicio"
                      :rules="{
                        required: dados.bloqueioHorarioFim ? true : false,
                      }"
                    >
                      <b-input-group>
                        <b-input-group-prepend class="input-group-text"><span>De:</span></b-input-group-prepend>
                        <cleave
                          id="bloqueioHorarioInicio"
                          v-model="dados.bloqueioHorarioInicio"
                          class="form-control cleave-height"
                          :raw="false"
                          :options="options.cleaveTime"
                          placeholder="Bloqueio Início"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <validation-provider
                      class="ml-1"
                      #default="{ errors }"
                      name="Bloqueio Fim"
                      :rules="{
                        required: dados.bloqueioHorarioInicio ? true : false,
                      }"
                    >
                      <b-input-group>
                        <b-input-group-prepend class="input-group-text"><span>Até:</span></b-input-group-prepend>
                        <cleave
                          id="bloqueioHorarioFim"
                          v-model="dados.bloqueioHorarioFim"
                          class="form-control cleave-height"
                          :raw="false"
                          :options="options.cleaveTime"
                          placeholder="Bloqueio Fim"
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0]
                          ? String(errors[0]).includes('obrigatório')
                            ? errors[0]
                            : 'Data Fim não pode ser Menor que Data Inicio'
                          : ''
                      }}</small>
                    </validation-provider>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>

      <!-- INICIO DO VERIFICA SENHA -->
      <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
        <AutenticaSenha @autenticarEvent="autenticarMethod" />
      </b-modal>
    </b-card-code>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { min_value, required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  // Para formatar
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import NaoAutorizadoModal from '../../error/nao-autorizado-modal.vue'
  import { formatarData, formatarDataGlobal, formatarParaNumber } from '@/libs/utils'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      AutenticaSenha,
      NaoAutorizadoModal,
      formatarData,
      formatarParaNumber,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        isBusy: true,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
          status: true,
        },
        required,
        min_value,
        mesmoUsuario: false,
        tipo: 1,
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'E-mail' },
        ],
        tipoStatus: [
          { value: null, text: 'Todos' },
          { value: true, text: 'Ativo' },
          { value: false, text: 'Desativado' },
        ],
        generoOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'M', text: 'Masculino' },
          { value: 'F', text: 'Feminino' },
        ],
        roleOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'master', text: 'Master' },
          { value: 'admin', text: 'Administrador' },
          { value: 'user', text: 'Usuário' },
        ],
        roleOpcoesAdmin: [
          { value: '', text: 'Selecione' },
          { value: 'admin', text: 'Administrador' },
          { value: 'user', text: 'Usuário' },
        ],
        roleOpcoesUser: [{ value: 'user', text: 'Usuário' }],
        options: {
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
          clevePhone: { phone: true, phoneRegionCode: 'BR', delimiter: ' ' },
          cleaveTime: {
            time: true,
            timePattern: ['h', 'm'],
            delimiter: ':',
          },
          cleaveIp: {
            numericOnly: true,
            delimiters: ['.', '.', '.', '.'],
            blocks: [3, 3, 3, 3],
            uppercase: true,
          },
        },
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        fields: [
          {
            key: 'opcoes',
            label: 'Opções',
          },
          { key: 'name', label: 'Nome', sortable: true },
          { key: 'email', label: 'E-mail', sortable: true },
          {
            key: 'dataNascimento',
            label: 'Data Nascimento',
            sortable: true,
            formatter: (value, key, item) => {
              if (value) return formatarData(value)
            },
          },
          {
            key: 'genero',
            label: 'Gênero',
            sortable: true,
            formatter: (value, key, item) => {
              if (value == 'M') return 'Masculino'
              else return 'Feminino'
            },
          },
          { key: 'role', label: 'Tipo de usuario', sortable: true },
          {
            key: 'ativo',
            label: 'Status',
            sortable: true,
          },
          { key: 'role', label: 'Grupo de acesso', sortable: true },
        ],
        items: [],
        dados: {
          grupoAcessoId: null,
        },
        instituto: [],
        grupoAcessoId: [],
        grupoAcesso: [],
        grupoAcessoPorInstituto: [],
        dadosUsuarioSelecionado: {},
        acesso: {
          CadastroUsuario: null,
          CadastroUsuarioConsulta: null,
          CadastroUsuarioCriarNovo: null,
          CadastroUsuarioEditar: null,
          CadastroUsuarioExcluir: null,
        },
        role: [
          {
            master: 'success',
            admin: 'primary',
            user: 'warning',
          },
        ],
        politicaSenhas: {
          senhaQuantidadeCaractere: 6,
          senhaCaractereEspecial: false,
          senhaLetraMaiuscula: false,
          senhaLetraMinuscula: false,
          senhaNumero: false,
        },
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    async mounted() {
      this.carregarInstituto()
      if (this.userData.role == 'master') {
        this.acesso.CadastroUsuario = true
        this.acesso.CadastroUsuarioConsulta = true
        this.acesso.CadastroUsuarioCriarNovo = true
        this.acesso.CadastroUsuarioEditar = true
        this.acesso.CadastroUsuarioExcluir = true
      } else {
        this.acesso.CadastroUsuario = this.userData.GrupoAcesso.CadastroUsuario
        this.acesso.CadastroUsuarioConsulta = this.userData.GrupoAcesso.CadastroUsuarioConsulta
        this.acesso.CadastroUsuarioCriarNovo = this.userData.GrupoAcesso.CadastroUsuarioCriarNovo
        this.acesso.CadastroUsuarioEditar = this.userData.GrupoAcesso.CadastroUsuarioEditar
        this.acesso.CadastroUsuarioExcluir = this.userData.GrupoAcesso.CadastroUsuarioExcluir
      }

      this.carregarGrupoAcesso(this.userData.Instituto.id)

      await this.getPoliticaSenhas()
      // Conforme reunião retiramos a consulta automática
      // this.carregarGrid();
      this.isBusy = false
    },
    methods: {
      isEmailValid() {
        return this.dados.email == ''
          ? ''
          : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
              this.dados.email,
            )
          ? true
          : false
      },
      pesquisarUsuario() {
        this.pesquisar = { ...this.pesquisar, currentPage: 1 }
        this.carregarGrid()
      },
      async carregarGrid() {
        this.items = []
        this.dados = {}
        this.grupoAcesso = []
        this.isBusy = true

        try {
          const response = await useJwt.pesquisar('cadastro/user/GetPesquisarUsuario', {
            ...this.pesquisar,
            role: this.userData.role,
            institutoSelecionado: this.userData.institutoSelecionado,
          })

          this.isBusy = false
          this.items = response.data

          this.items = this.items.sort((userA, userB) => userA.name.localeCompare(userB.name))

          this.totalRows = this.items.length
        } catch (error) {
          console.error(error)
        }
      },
      carregarInstituto() {
        useJwt
          .get('cadastro/instituto')
          .then((response) => {
            this.instituto = response.data
          })
          .catch((error) => {
            console.error(error)
          })
      },
      carregarGrupoAcesso(institutoId) {
        if (institutoId) {
          const parametro = { institutoId: institutoId }
          useJwt
            .post('grupo-acesso/GetGrupoPorInst', parametro)
            .then((response) => {
              if (response?.data) {
                this.grupoAcesso = response?.data
                  .map((grupo) => {
                    const instituto = this.instituto.find((inst) => inst.id === grupo.institutoId)
                    return {
                      nome: instituto?.sigla ? `${grupo?.nome} - ${instituto.sigla}` : grupo?.nome,
                      id: grupo?.id,
                      institutoId: grupo?.institutoId,
                    }
                  })
                  .sort((a, b) => a.institutoId - b.institutoId)
              }
            })
            .catch((error) => {
              console.error(error)
            })
        } else {
          useJwt
            .post('grupo-acesso/GetAllGrupoAcesso')
            .then((response) => {
              this.grupoAcesso = response.data
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      novoRegistro() {
        this.items.mode = 'novo'
        this.dados = {
          avatar: '13-small.png',
          genero: '',
          role: '',
        }
        this.carregarInstituto()
        this.carregarGrupoAcesso(this.userData.Instituto.id)

        this.$refs['modal-lg'].show()
      },
      authEAlteraStatus(item) {
        this.dadosUsuarioSelecionado = item
        this.items.mode = 'authEAlteraStatus'
        this.$refs['modal-auth-senha'].show()
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.items.mode == 'authEAlteraStatus') {
            this.alteraStatus(this.dadosUsuarioSelecionado.ativo)
          }
        }
      },
      alteraStatus(isActived) {
        if (isActived) {
          this.desativar(this.dadosUsuarioSelecionado.id)
        } else {
          this.ativar(this.dadosUsuarioSelecionado.id)
        }
      },
      ativar(id) {
        useJwt
          .put(`cadastro/user/ativarUsuario/` + id)
          .then((response) => {
            if (response.data) {
              this.carregarGrid()
              this.$message.success('Usuário ativado com sucesso!')
            } else {
              this.$message.error('Erro ao ativar o usuário!')
            }
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao ativar o usuário!')
          })
      },
      desativar(id) {
        useJwt
          .put(`cadastro/user/desativarUsuario/` + id)
          .then((response) => {
            if (response.data) {
              this.carregarGrid()
              this.$message.success('Usuário desativado com sucesso!')
            } else {
              this.$message.error('Erro ao desativar o usuário!')
            }
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao desativar o usuário!')
          })
      },
      editar(item) {
        this.mesmoUsuario = false
        this.carregarInstituto()
        useJwt
          .edit(`cadastro/user`, item)
          .then(async (response) => {
            if (this.userData.role === 'master') {
              this.carregarGrupoAcesso(response.data.Instituto.map((item) => item.id))
            } else {
              this.carregarGrupoAcesso(this.userData.Instituto.id)
            }
            this.dados = {
              ...response.data,
              password: response.data.password,
              dataNascimento: formatarData(response.data.dataNascimento),
            }
            if (response.data) {
              const dadosUser = response.data

              if (this.dados.id == this.userData.id) {
                this.mesmoUsuario = true
              }

              if (this.dados.role == 'admin' && dadosUser.Instituto.length > 1) {
                this.grupoAcesso = this.grupoAcesso.map((item) => ({
                  institutoId: item.institutoId,
                  id: item.id,
                  nome: `${item.nome}`,
                }))

                this.dados.GrupoAcesso = dadosUser.GrupoAcesso.map((grupo) => {
                  const instituto = dadosUser.Instituto.find((inst) => inst.id === grupo.institutoId)
                  return {
                    nome: `${grupo.nome} - ${instituto.sigla}`,
                    id: grupo.id,
                    institutoId: grupo.institutoId,
                  }
                }).sort((a, b) => a.institutoId - b.institutoId)
              }

              this.items.mode = 'editar'
              this.$refs['modal-lg'].show()
            } else {
              this.$message.error('Erro ao buscar os dados do usuário!')
            }
          })
          .catch((error) => {
            console.error(error)
          })
      },
      async salvar(bvModalEvt) {
        bvModalEvt.preventDefault()

        const isFormValid = await this.$refs.formTomador.validate()
        const isSelectionValid = () =>
          (this.dados.role == 'admin' || this.dados.role == 'user') && this.dados.grupoAcessoId == ''

        if (isFormValid) {
          if (!isSelectionValid) {
            this.$message.error('Erro: É obrigatório a seleção de um Grupo de Acesso a Administradores e Usuários.')
            return
          }

          if (!this.isEmailValid == true) {
            this.$message.error('Erro: O e-mail digitado não é válido.')
            return
          }

          if (!this.isValidoPoliticaSenha('salvar') && this.items.mode == 'novo') {
            this.$message.error('Erro: senha deve cumprir todos os requisitos')
            return
          }
          if (this.userData.role == 'user') {
            this.dados.GrupoAcesso = [this.userData.GrupoAcesso.id]
            this.dados.Instituto = [this.userData.Instituto.id]
            this.dados.role = 'user'
          }

          let idInstituto
          if (this.userData.role == 'master') {
            if (this.dados.role == 'user') {
              if (Array.isArray(this.dados.Instituto)) {
                idInstituto = this.dados.Instituto.map((instituto) => instituto.id)
              } else {
                idInstituto = [this.dados.Instituto]
              }
            } else if (this.dados.role == 'master') {
              idInstituto = []
            } else if (Array.isArray(this.dados.Instituto)) {
              if (
                this.dados.Instituto &&
                this.dados.Instituto.length > 0 &&
                typeof this.dados.Instituto[0] === 'object' &&
                'id' in this.dados.Instituto[0]
              ) {
                idInstituto = this.dados.Instituto.map((instituto) => instituto.id)
              } else if (this.dados.Instituto.length > 0 && typeof this.dados.Instituto[0] === 'number') {
                idInstituto = [...this.dados.Instituto]
              } else {
                idInstituto = []
              }
            } else {
              idInstituto = null
            }
          } else {
            idInstituto = [this.userData.Instituto.id]
          }

          let idGruposAcesso
          if (this.userData.role == 'master') {
            if (this.dados.role == 'user') {
              if (Array.isArray(this.dados.GrupoAcesso)) {
                idGruposAcesso = this.dados.GrupoAcesso.map((grupo) => grupo.id)
              } else {
                idGruposAcesso = [this.dados.GrupoAcesso.id]
              }
            } else if (!!this.dados.GrupoAcesso && this.dados.GrupoAcesso.length >= 1) {
              idGruposAcesso = this.dados.GrupoAcesso.map((grupo) => grupo.id)
            } else {
              idGruposAcesso = null
            }
          } else {
            if (Array.isArray(this.dados.GrupoAcesso) && this.userData.role != 'user') {
              idGruposAcesso = this.dados.GrupoAcesso.map((grupo) => grupo.id)
            } else if (this.userData.role != 'user') {
              idGruposAcesso = [this.dados.GrupoAcesso.id]
            }
          }

          const data = {
            ...this.dados,
            dataNascimento: formatarDataGlobal(this.dados.dataNascimento),
            institutoId: this.dados.institutoId ?? undefined,
            grupoAcessoId: this.dados.grupoAcessoId ?? undefined,
            Instituto: idInstituto,
            GrupoAcesso: idGruposAcesso,
          }

          try {
            if (!this.dados.id) {
              await useJwt.post(`cadastro/user`, data)
              this.$message.success('Registro cadastrado com sucesso!')
            } else {
              await useJwt.update(`cadastro/user/updateUsuario`, this.dados.id, data)
              this.$message.success('Registro atualizado com sucesso!')
            }
          } catch (e) {
            this.$message.error('Erro ao modificar o registro!')
          } finally {
            this.$refs['modal-lg'].hide()
            this.carregarGrid()
          }
        }
      },
      async getPoliticaSenhas() {
        useJwt
          .get(`cadastro/instituto/politicaSenhas/${this.userData.Instituto['id']}`)
          .then((res) => {
            this.politicaSenhas = res.data
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro:' + error.response.data.msg)
            return
          })
      },
      isValidoPoliticaSenha(campo) {
        const politica = this.politicaSenhas
        let regexStr = '^'

        if (this.dados.password === undefined) {
          return false
        }
        switch (campo) {
          case 'senhaQuantidadeCaractere':
            if (politica.senhaQuantidadeCaractere) {
              regexStr += `.{${politica.senhaQuantidadeCaractere},}$`
            }
            break
          case 'senhaLetraMaiuscula':
            if (politica.senhaLetraMaiuscula) {
              regexStr += '(?=.*[A-Z])'
            }
            break
          case 'senhaLetraMinuscula':
            if (politica.senhaLetraMinuscula) {
              regexStr += '(?=.*[a-z])'
            }
            break
          case 'senhaNumero':
            if (politica.senhaNumero) {
              regexStr += '(?=.*\\d)'
            }
            break
          case 'senhaCaractereEspecial':
            if (politica.senhaCaractereEspecial) {
              regexStr += '(?=.*[!@#\\$%\\^&\\*])'
            }
            break
          default:
            if (politica.senhaQuantidadeCaractere) {
              regexStr += `(?=.{${politica.senhaQuantidadeCaractere},})`
            }
            if (politica.senhaLetraMaiuscula) {
              regexStr += '(?=.*[A-Z])'
            }
            if (politica.senhaLetraMinuscula) {
              regexStr += '(?=.*[a-z])'
            }
            if (politica.senhaNumero) {
              regexStr += '(?=.*\\d)'
            }
            if (politica.senhaCaractereEspecial) {
              regexStr += '(?=.*[!@#\\$%\\^&\\*])'
            }
        }
        const regex = new RegExp(regexStr)
        return regex.test(this.dados.password)
      },

      async verificarGrupoAcesso(dados) {
        if (dados) {
          const institutoIdAtual = this.dados.GrupoAcesso.institutoId

          let itemsFiltrados = dados.filter((dado) => dado.institutoId !== institutoIdAtual)

          let idsUnicos = new Set()
          itemsFiltrados = itemsFiltrados.filter((item) => {
            if (idsUnicos.has(item.institutoId)) {
              return false
            } else {
              idsUnicos.add(item.institutoId)
              return true
            }
          })
          this.dados.GrupoAcesso = itemsFiltrados
        }
      },
    },
  }
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .mx-calendar-content .cell.active {
    background-color: #3949ab !important;
  }
  .mx-calendar-content .cell:hover {
    background-color: #7886d1 !important;
    color: white !important;
  }
</style>
